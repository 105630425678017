import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SliderItems from "../components/slider_items"
import ProjectCarousel from "../components/project_carousel"
import PortfolioStyles from "./portfolio.module.css"
import Img from "gatsby-image"
import parse from "html-react-parser"

export default function Portfolio2Page({ pageContext }) {
  const data = useStaticQuery(graphql`
    query AwardsCarousel {
      allAwardsJson {
        edges {
          node {
            name
            image {
              childImageSharp {
                fluid(maxWidth: 100, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)
  const title = "Portfolio | " + pageContext.data.name

  let logoAwards = []
  let awards = pageContext.data.awards
  let total = awards.split(",").length
  if (total === 1) {
    if (awards.length > 0) logoAwards.push(awards.trim())
  } else {
    awards.split(",").forEach(i => {
      logoAwards.push(i.trim())
    })
  }
  return (
    <Layout>
      <SEO
        title={title}
        description={pageContext.data.name}
        image={pageContext.data.cover.childImageSharp.fluid.src}
      />
      <div className="basic-breadcrumb-area gray-bg pt-70 pb-40">
        <div className="container">
          <div className="basic-breadcrumb text-center">
            <h3 className="">{pageContext.data.name}</h3>
            <ol className="breadcrumb text-xs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/portfolio">Portfolio</Link>
              </li>
              <li className="active">details</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="portfolio-details-area pt-70 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="portfolio-full-img portfolio-slider owl-carousel">
                <SliderItems idProject={pageContext.data.id_project} />
              </div>
            </div>
          </div>
          <div className="row mt-70">
            <div className="col-md-3">
              <ul className="project-details">
                <li className="font-alt">
                  Categories: {pageContext.data.category}
                </li>
                <li className="font-alt">Released: {pageContext.data.date}</li>

                <li className="font-alt">Client: {pageContext.data.client}</li>
              </ul>
              <div className="portfolio-view-btn mt-20">
                <a className="btn" href={pageContext.data.link} target="_blank">
                  View Project
                </a>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row multi-columns-row">
                <div className="col-sm-6 col-md-6 mb-30">
                  <h5 className="font-alt m-t-0">Description</h5>
                  <p>{parse(pageContext.data.description)}</p>
                </div>

                <div className="col-sm-6 col-md-6 mb-30">
                  <h5 className="font-alt m-t-0">Features & Technologies</h5>
                  <p>{pageContext.data.tech}</p>
                </div>
                <div className="col-sm-6 col-md-6 mb-30">
                  <h5 className="font-alt m-t-0">Awards</h5>
                  <p>
                    {data.allAwardsJson.edges.map(i => {
                      let name = i.node.name
                      if (logoAwards.includes(name)) {
                        logoAwards.splice(logoAwards.indexOf(name), 1)
                        return (
                          <Img
                            key={i.node.name}
                            alt={i.node.name}
                            style={{ maxWidth: "100px" }}
                            fluid={i.node.image.childImageSharp.fluid}
                          ></Img>
                        )
                      } else return ""
                    })}
                    {logoAwards.map(i => {
                      return i
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="basic-separator"></div>

      <div className="related-post-area ptb-90">
        <div className="container">
          <div className={PortfolioStyles.areaTitle + " text-center"}>
            <h2>Related Portfolio</h2>
            <p>
              You can find other projects related to the current one you are
              looking at now, you might find something cool I have done.
            </p>
          </div>
        </div>
        <div id="related-active" className=" owl-carousel">
          <ProjectCarousel
            category={pageContext.data.category}
            idProject={pageContext.data.id_project}
          ></ProjectCarousel>
        </div>
      </div>
    </Layout>
  )
}
