import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import Img from "gatsby-image"
import Carousel, { Modal, ModalGateway } from "react-images"
import Slider from "react-slick"
import SliderItemsStyles from "./slider_item.module.css"

const NextArrow = ({ ...props }) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className + " slider_custom"}
      style={{
        ...style,
      }}
      onClick={onClick}
    ></div>
  )
}

const PrevArrow = ({ ...props }) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className + " slider_custom slider_custom_prev"}
      style={{
        ...style,
      }}
      onClick={onClick}
    ></div>
  )
}

const SliderItems = ({ idProject, ...props }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)

  const data = useStaticQuery(graphql`
    query SliderItem {
      allWorksJson {
        edges {
          node {
            id_project
            media {
              id
              src
              type
              image {
                id
                childImageSharp {
                  a: fluid(maxWidth: 1024, maxHeight: 800, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  b: fixed(height: 800, quality: 90) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  let views = []
  data.allWorksJson.edges.forEach(i => {
    if (i.node.id_project === idProject)
      i.node.media.forEach(z => {
        let type = "image"
        let src = ""
        if (z.type === "1")
          src = [z.image.childImageSharp.a, z.image.childImageSharp.b]
        else if (z.type === "2") {
          src = z.src
          type = "youtube"
        } else if (z.type === "3") {
          src = z.src
          type = "vimeo"
        }
        views.push({
          caption: "",
          name: "",
          src: src,
          type: type,
        })
      })
  })
  /*
  let viewsI = []
  data.allWorksJson.edges.forEach(i => {
    if (i.node.id_project === idProject)
      i.node.media.forEach(z => {
        let type = "image"
        let src = ""
        if (z.type === "1") {
          src = z.image.childImageSharp.b.src

          viewsI.push({
            caption: "",
            name: "",
            src: src,
            type: type,
          })
        }
      })
  })*/

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    autoplay: false,
    cssEase: "linear",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  return (
    <>
      <Slider {...settings}>
        {views.map((i, index) => (
          <CustomView
            key={index}
            data={i}
            isModal={false}
            onClick={() => {
              setModalOpen(true)
              setCurrentIndex(index)
            }}
          />
        ))}
      </Slider>
      <ModalGateway>
        {modalOpen ? (
          <Modal onClose={() => setModalOpen(false)}>
            <Carousel
              currentIndex={currentIndex}
              views={views}
              isModal={true}
              components={{ View: CustomView }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
/*

            objectFit="contain"
            objectPosition="10% 10%"
 */
const CustomView = ({ data, onClick, isModal, ...rest }) => {
  return (
    <div onClick={onClick} onKeyDown={onClick} style={{ textAlign: "center" }}>
      {data.type === "image" &&
        (isModal ? (
          <Img
            fixed={data.src[1]}
            style={{ width: "100%", paddingBottom: "70%", height: "0" }}
          />
        ) : (
          <Img fluid={data.src[0]} objectFit="contain" />
        ))}
      {data.type === "vimeo" && (
        <div className={SliderItemsStyles.videowrapper}>
          <iframe
            title={data.type}
            src={"//player.vimeo.com/video/" + data.src}
            frameBorder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen="1"
          ></iframe>
        </div>
      )}
      {data.type === "youtube" && (
        <div className={SliderItemsStyles.videowrapper}>
          <iframe
            title={data.type}
            id="ytplayer"
            type="text/html"
            align="middle"
            src={"https://www.youtube.com/embed/" + data.src}
            frameBorder="0"
            allowFullScreen="1"
          />
        </div>
      )}
    </div>
  )
}
export default SliderItems
