import { graphql, StaticQuery } from "gatsby"
import React from "react"
import Slider from "react-slick"
import portfolioItemStyles from "./project_carousel.module.css"
import { Link } from "gatsby"
import Img from "gatsby-image"

const ProjectCarousel = ({ category, idProject, ...props }) => (
  <StaticQuery
    query={graphql`
      query ProjectCarousel {
        allWorksJson {
          edges {
            node {
              cover {
                childImageSharp {
                  fluid(maxWidth: 500, maxHeight: 500, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              prefix
              name
              category
              id_project
              media {
                src
              }
            }
          }
        }
      }
    `}
    render={data => {
      var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        autoplay: true,
        cssEase: "linear",
      }
      var settingsMobile = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        autoplay: true,
        cssEase: "linear",
      }
      return (
        <div>
          <Slider className={portfolioItemStyles.sliderDesktop} {...settings}>
            {data.allWorksJson.edges.map(i => {
              if (
                i.node.category === category &&
                i.node.id_project !== idProject
              )
                return (
                  <div key={i.node.name}>
                    <div className={portfolioItemStyles.portfolioItem}>
                      <div className="portfolio-wrapper">
                        <div className="portfolio-thumb">
                          <Img
                            alt={i.node.name}
                            fluid={i.node.cover.childImageSharp.fluid}
                          />
                          <div className="view-icon">
                            <Link to={i.node.prefix}>
                              <i className="ion-arrow-right-c"></i>
                            </Link>
                          </div>
                        </div>
                        <Link to={i.node.prefix}>
                          <div className="portfolio-caption text-left">
                            <div className="work-tag">
                              <p>{i.node.name}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              else return ""
            })}
          </Slider>
          <Slider
            className={portfolioItemStyles.sliderMobile}
            {...settingsMobile}
          >
            {data.allWorksJson.edges.map(i => {
              if (
                i.node.category === category &&
                i.node.id_project !== idProject
              )
                return (
                  <div key={i.node.name}>
                    <div className={portfolioItemStyles.portfolioItem}>
                      <div className="portfolio-wrapper">
                        <div className="portfolio-thumb">
                          <Img
                            alt={i.node.name}
                            fluid={i.node.cover.childImageSharp.fluid}
                          />
                          <div className="view-icon">
                            <a href="#">
                              <i className="ion-arrow-right-c"></i>
                            </a>
                          </div>
                        </div>
                        <Link to={i.node.prefix}>
                          <div className="portfolio-caption text-left">
                            <div className="work-tag">
                              <p>{i.node.name}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              else return ""
            })}
          </Slider>
        </div>
      )
    }}
  />
)

export default ProjectCarousel
